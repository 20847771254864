<template>
  <div>
    <v-menu v-model="shower" :position-x="AreaX" :position-y="AreaY" close-on-click close-on-content-click>
      <v-list dense tile>
         <!-- COPY ONLY ALL FILES (ปิดก่อนชั่วคราว) -->
         <!-- <v-list-item class="multiitem" v-if="check_new_copypast" @click="fn_selectFunction(Item_CopyAllFiles[0].select_funtion)">
          <v-list-item-icon class="multiitem">
            <v-icon medium class="multiitem" > {{ Item_CopyAllFiles[0].icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="multiitem">
            <v-list-item-title class="multiitem">{{ $t(Item_CopyAllFiles[0].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>  -->
        <v-list-item class="multiitem" v-for="(item, index) in ItemOption" :key="index" @click="fn_selectFunction(item.select_funtion)">
          <v-list-item-icon class="multiitem">
            <v-icon medium class="multiitem" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="multiitem">
            <v-list-item-title class="multiitem">{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item class="multiitem" v-if="check_new_copypast"  @click="fn_selectFunction(Item_newcopyfile[0].select_funtion)">
          <v-list-item-icon class="multiitem">
            <v-icon medium class="multiitem" > {{ Item_newcopyfile[0].icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="multiitem">
            <v-list-item-title class="multiitem">{{ $t(Item_newcopyfile[0].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>  -->
        <!-- <v-list-item @click="fn_copyfile()">
          <v-list-item-icon>
            <v-icon v-text="Itemcopyfile[0].icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(Itemcopyfile[0].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- ไม่ใช้วาง -->
        <!-- <v-list-item @click="fn_pastefile()" v-if="checkbtncopy == true">
          <v-list-item-icon>
            <v-icon v-text="Itemcopyfile[1].icon"> </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(Itemcopyfile[1].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-menu>
    <!-- :filemultipledownload="filemultipledownload" -->
    <multi_movefile
      :show="opendialog"
      :filemultipledownload="listdragfile"
      :parentfolder="parentfolder"
      @closedialog="opendialog = false, $emit('createevent')"
      @reload="fn_parentreload()"
      @closeappsbar="$emit('closeappsbar'), $emit('createevent')"
    ></multi_movefile>
    <dialogsnackbarmultidownload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload = false, $emit('createevent')"
      :percent="percent"
      :date="datetime"
      :btsdownload="btsdownload"
    ></dialogsnackbarmultidownload>
    <!-- :data="filemultipledownload" -->
    <dialogcheckmultidownload
      :show="opendialogcheckmultidownload"
      @closedialog="opendialogcheckmultidownload = false, $emit('createevent')"
      :data="listdragfile"
      :rootfile="rootfile"
    ></dialogcheckmultidownload>
    <!-- :multihashtag="filemultipledownload" -->
    <dialogeditdeletehashtag
      :show="opendialogeditdeletehashtag"
      :multihashtag="listdragfile"
      @close="opendialogeditdeletehashtag = false, $emit('createevent')"
      @reload="$emit('reload'), $emit('createevent')"
      :filedata="{}"
      :listcheckhashtag="{}"
    ></dialogeditdeletehashtag>
    <dialogcheckmultidelete
      :show="opendialogcheckmultidelete"
      :filedata="listdragfile"
      @closedialog="opendialogcheckmultidelete = false, $emit('createevent')"
      @closedialogreload="
        (opendialogcheckmultidelete = false), $emit('reload'), $emit('createevent')
      "
      :status_data="status_data"
    ></dialogcheckmultidelete>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import axios from "axios";
import dialogeditdeletehashtag from "../optional/dialog-editdeletehashtag";
const multi_movefile = () => import("../optional/dialog-multimovefile");
const dialogsnackbarmultidownload = () => import("../optional/dialog-snackbarmultidowmload");
const dialogcheckmultidownload = () => import("../optional/dialog-checkmultidownload");
const dialogcheckmultidelete = () => import("../optional/dialog-checkmultidelete");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      arrayCheckfolder: [],
      check_new_copypast: false,
      checkbtncopy: false,
      listfilecopy: [],
      listcopyfile: [],
      btsdownload: false,
      opendialog: false,
      opendialogcheckmultidownload: false,
      percent: 0,
      opensnackbarmultidownload: false,
      opendialogeditdeletehashtag: false,
      opendialogcheckmultidelete: false,
      datetime: "",
      check_delete: 0,
      ItemOption: [
        {
          text: "multirightclick.download",
          select_funtion: "download",
          icon: "mdi-download",
        },
        {
          text: "multirightclick.delete",
          select_funtion: "delete",
          icon: "delete",
        },
        {
          text: "multirightclick.star",
          select_funtion: "star",
          icon: "star",
        },
        {
          text: "multirightclick.movefiles",
          select_funtion: "movefile",
          icon: "mdi-folder-swap",
        },
        {
          text: "multirightclick.hashtag",
          select_funtion: "hashtag",
          icon: "mdi-pencil",
        },
      ],
      Itemcopyfile: [
        {
          text: "คัดลอกที่เลือก",
          select_funtion: "copyfile",
          icon: "file_copy",
        },
        {
          text: "วาง",
          select_funtion: "paste",
          icon: "mdi-file-replace",
        },
      ],
      Item_newcopyfile: [
        {
          text: "copyandpaste.copy_btn",
          select_funtion: "copyfile",
          icon: "file_copy",
        },
      ],
      Item_CopyAllFiles: [
        {
          text: "copyandpaste.files_copy",
          select_funtion: "copy_allFiles",
          icon: "mdi-clipboard-file",
        }
      ],
      listdragfile: [],
    };
  },
  props: ["show", "AreaX", "AreaY", "filemultipledownload", "parentfolder", "file","status_data", "arrayfileidselect", "rootfile"],
  components: {
    multi_movefile,
    dialogsnackbarmultidownload,
    dialogcheckmultidownload,
    dialogeditdeletehashtag,
    dialogcheckmultidelete
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if(this.arrayfileidselect.length <= 1){
          this.$emit("closecurrentonly");
        } else {
          if(this.show){
            this.arrayfileidselect.forEach((item) => 
            this.arrayCheckfolder.push(this.rootfile.filter((itemRoot) => itemRoot.file_id === item && itemRoot.file_type === "folder")[0] === undefined ? false : true))
            if(this.arrayCheckfolder.find((itemBoolean) => itemBoolean === true)){
              this.check_new_copypast = false
            } else {
              this.check_new_copypast = this.arrayfileidselect
               .map((itemMap) => this.rootfile.filter((itemFile) => itemFile.file_id === itemMap)[0])
               .filter((itemFilter) => ["04", "05", "06"].includes(itemFilter.file_permission_2)).length >= 1 ? true : false
            }
          } else {
            this.arrayCheckfolder = []
            console.log("Close multi right click")
          }
        }
        // if (this.filemultipledownload.length <= 1) {
        // if (this.arrayfileidselect.length <= 1) {
        //   this.$emit("closecurrentonly");
        // } else {  //กรณีที่ array file ที่เลือกมากกว่า จะต้องมีการเช็คว่า items ทั้งหมดนี้แถมมี folder มาด้วย
        //   this.check_new_copypast = this.arrayfileidselect.forEach((item) => this.rootfile.filter((itemRoot) => itemRoot.file_id === item))
        //   console.log("Test multi ", this.arrayfileidselect)
        // }
        // let get_sessionfilecopy = [];
        // get_sessionfilecopy = sessionStorage.getItem("filecopy");
        // // console.log("get_sessionfilecopy",get_sessionfilecopy);
        // if (get_sessionfilecopy === "" || get_sessionfilecopy === null || get_sessionfilecopy === undefined) {
        //   this.checkbtncopy = false;
        // } else {
        //   this.checkbtncopy = true;
        // }
        return this.show;
      },
      set(value) {
        // console.log("Valuee open ", value)
        // if (!value) {
        //   this.$emit("closecurrentonly");
        // }
      },
    },
  },
  methods: {
    //copyfile
    fn_copyfile() {
      this.listcopyfile = [];
      console.log("this.filemultipledownload", this.filemultipledownload);
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        if (
          this.filemultipledownload[i].file_type !== "folder" &&
          this.filemultipledownload[i].file_permission_2 !== "01"
        ) {
          this.listcopyfile.push(this.filemultipledownload[i]);
        }
      }
      sessionStorage.setItem("filecopy", JSON.stringify(this.listcopyfile));
      Toast.fire({
        icon: "success",
        title: "คัดลอกไฟล์ที่เลือก",
      });
    },
    //วางไฟล์
    async fn_pastefile() {
      if (this.$router.currentRoute.path === "/mydrive") {
      } else {
        this.this.listfilecopy = [];
        let data_copy = sessionStorage.getItem("filecopy");
        let data_paste = JSON.parse(data_copy);
        console.log("data_paste", data_paste);

        for (let i = 0; i < data_paste.length; i++) {
          this.listfilecopy.push({
            data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
            data_id: data_paste[i].file_id,
          });
        }
        console.log("listfilecopy", this.listfilecopy);
        // if(currentfile.file_type === 'folder'){}
        let payload = {
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          copied: this.listfilecopy,
        };
        console.log("payloadcopyfile", payload);
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);
        let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
          headers: { Authorization: auth.code },
        });
        console.log(resp);
        try {
          if (resp.data.status === "OK") {
            // this.createprogress = false;
            Toast.fire({
              icon: "success",
              title: "วาง",
            });
            this.$emit("reload");
          } else {
            // this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: resp.data.errorMessage,
            });
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      }
    },
    // NEW 2024 COPY PASTE FILES
    // ทำสัมเนา
    fn_copypastfiles () {
      console.log("TESSST ", this.arrayfileidselect)
      //console.log("Array Selected ", this.arrayfileidselect.map((itemMap) => this.rootfile.filter((itemFile) => itemFile.file_id === itemMap)[0]))
      // console.log("All root file ", this.rootfile.filter(item => this.arrayfileidselect.includes(item.file_id)));
      // console.log("FIlter item ", this.arrayfileidselect
      // .map((itemMap) => this.rootfile.filter((itemFile) => itemFile.file_id === itemMap)[0])
      // .filter((itemFilter) => ["04", "05", "06"].includes(itemFilter.file_permission_2)))
      let arrayfiles_selectd =  this.arrayfileidselect
      .map((itemMap) => this.rootfile.filter((itemFile) => itemFile.file_id === itemMap)[0])
      .filter((itemFilter) => ["04", "05", "06"].includes(itemFilter.file_permission_2))
      .map((itemMaps) => itemMaps.file_id)

      this.$emit("createevent")
      this.$emit("gatherfiles_copypaste", arrayfiles_selectd)
    },
    // ก็อปปี้ทุกไฟล์
    fn_copy_allFiles () {
      let arrayfiles_selected =  this.arrayfileidselect
      .map((itemMap) => this.rootfile.filter((itemFile) => itemFile.file_id === itemMap)[0])
      .filter((itemFilter) => ["03", "04", "05", "06"].includes(itemFilter.file_permission_2))
      .map((itemMaps) => itemMaps.file_id)
      console.log("Array Selected ", arrayfiles_selected)
      this.$emit("createevent")
      this.$emit("copied_file", {files_: arrayfiles_selected})
    },
    fn_selectFunction(path) {
      this.listdragfile = []
      for (let i = 0; i < this.arrayfileidselect.length; i++) {
        let file = this.rootfile.filter(item => item.file_id === this.arrayfileidselect[i])
        this.listdragfile.push(file[0])
      }
      if (path == "download") {
        // this.opendialogcheckmultidownload = true;
        this.opensnackbarmultidownload = true;
        this.fn_multipledownload_new();
      } else if (path == "delete") {
        // this.fn_multidelete();
        this.opendialogcheckmultidelete = true;
      } else if (path == "movefile") {
        this.opendialog = true;
      } else if (path == "hashtag") {
        this.opendialogeditdeletehashtag = true;
      } else if (path === "copyfile") {
        console.log("ทดสอบบ ทำสำเนา")
        this.fn_copypastfiles()
      } else if (path === "copy_allFiles") {
        this.fn_copy_allFiles()
      } else {
        this.fn_setmultiupdate();
      }
    },
    // function download pack
    fn_clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    fn_zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ไม่ได้ใช้เปลี่ยยนเป็นส่งค่าไปใน dialog
    //ไปใช้ใน dialog checkmultidownload
    // function download pack
    // async fn_multipledownload() {
    //   this.btsdownload = true;
    //   console.log("muti download rightclick", this.filemultipledownload);
    //   this.percentCompleted = 0;
    //   this.percent = 0;
    //   var d = new Date();
    //   this.datetime =
    //     d.toISOString().slice(0, 10) +
    //     " " +
    //     "at" +
    //     " " +
    //     this.fn_zeroPadding(d.getHours(), 2) +
    //     "-" +
    //     this.fn_zeroPadding(d.getMinutes(), 2) +
    //     "-" +
    //     this.fn_zeroPadding(d.getSeconds(), 2);

    //   // console.log("this.datetime",this.datetime);
    //   if (this.filemultipledownload.length < 1) {
    //     Toast.fire({
    //       icon: "warning",
    //       title: this.$t("myinboxPage.msg.nofile"),
    //     });
    //   } else {
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultipledownload.length; i++) {
    //       // push type
    //       if (this.filemultipledownload[i]["file_type"] === "folder") {
    //         filedata.push({
    //           id: this.filemultipledownload[i]["file_id"],
    //           type: this.filemultipledownload[i]["file_type"],
    //         });
    //       } else {
    //         filedata.push({
    //           id: this.filemultipledownload[i]["file_id"],
    //           type: "file",
    //         });
    //       }
    //     }
    //     let payload = {
    //       list: filedata,
    //       account_id: this.dataAccountId,
    //     };
    //     console.log("payloads", payload);
    //     // this.snackbardowloadfile = true;
    //     this.opensnackbarmultidownload = true;
    //     let auth = await gbfGenerate.generateToken();

    //     this.axios
    //       .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
    //         onDownloadProgress: (progressEvent) => {
    //           // console.log(progressEvent);
    //           let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    //           // console.log(progresspercent);
    //           if (progresspercent >= 95) {
    //             this.percentCompleted = 100;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //           this.percent = this.percentCompleted;
    //           // console.log("this.percent",this.percent);
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer",
    //         headers: { Authorization: auth.code },
    //       })
    //       .then((response) => {
    //         console.log(response);
    //         if (response.statusText === "OK") {
    //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             this.opensnackbarmultidownload = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
    //             Toast.fire({ icon: "error", title: res_error.errorMessage });
    //             } else {
    //               this.percentCompleted = 100;
    //               this.btsdownload = false;

    //               let headers = response.headers;
    //               let blob = new Blob([response.data],{type:headers['content-type']});
    //               let link = document.createElement("a");
    //               link.href = window.URL.createObjectURL(blob);
    //               link.download = this.datetime;
    //               link.click();

    //               // const blob = new Blob([response.data]);
    //               // const content = response.headers["content-type"];
    //               // saveAs(blob, this.datetime + ".zip");

    //               setTimeout(() => {
    //                 this.opensnackbarmultidownload = false;
    //               }, 2500);
    //             }
              
    //         }
          
    //         // this.clearmultipledownload();
    //       })
    //       .catch((error) => {
    //         this.btsdownload = false;
    //         this.$store.dispatch("show_loading", false);
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("myinboxPage.msg.apierror"),
    //         });
    //         console.log(error);
    //       });
    //     this.$emit("closecurrentonly");
    //     this.$emit("clearparentvalue");
    //   }
    // },
    async fn_multipledownload_new() {
      this.btsdownload = true;
      this.$emit("createevent");
      console.log("muti download rightclick", this.filemultipledownload);
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.fn_zeroPadding(d.getHours(), 2) +
        "-" +
        this.fn_zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.fn_zeroPadding(d.getSeconds(), 2);

      // console.log("this.datetime",this.datetime);
      if (this.listdragfile.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let filedata = [];
        for (let i = 0; i < this.listdragfile.length; i++) {
          // push type
          if (this.listdragfile[i]["file_type"] === "folder") {
            filedata.push({
              id: this.listdragfile[i]["file_id"],
              type: this.listdragfile[i]["file_type"],
            });
          } else {
            filedata.push({
              id: this.listdragfile[i]["file_id"],
              type: "file",
            });
          }
        }
        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        console.log("payloads", payload);
        // this.snackbardowloadfile = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();

        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
            onDownloadProgress: (progressEvent) => {
              // console.log(progressEvent);
              let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              // console.log(progresspercent);
              if (progresspercent >= 95) {
                this.percentCompleted = 100;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
              // console.log("this.percent",this.percent);
            },
            withCredentials: false,
            responseType: "arraybuffer",
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            // if (response.statusText === "OK") {
            if (response.status === 200) {
              try {
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                if (res_error.status === "ER") {
                  this.opensnackbarmultidownload = false;
                  Toast.fire({ icon: "error", title: res_error.errorMessage });
                } else {
                  this.percentCompleted = 100;
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = this.datetime;
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                  }, 2500);
                }
              } catch (error) {
                console.log("error", error);
                this.percentCompleted = 100;
                this.btsdownload = false;
                let headers = response.headers;
                let blob = new Blob([response.data],{type:headers['content-type']});
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = this.datetime;
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
              // if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
              //   this.opensnackbarmultidownload = false;
              //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              //   Toast.fire({ icon: "error", title: res_error.errorMessage });
              //   } else {
              //     this.percentCompleted = 100;
              //     this.btsdownload = false;

              //     let headers = response.headers;
              //     let blob = new Blob([response.data],{type:headers['content-type']});
              //     let link = document.createElement("a");
              //     link.href = window.URL.createObjectURL(blob);
              //     link.download = this.datetime;
              //     link.click();

              //     // const blob = new Blob([response.data]);
              //     // const content = response.headers["content-type"];
              //     // saveAs(blob, this.datetime + ".zip");

              //     setTimeout(() => {
              //       this.opensnackbarmultidownload = false;
              //     }, 2500);
              //   }
              
            } else {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          
            // this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
        this.$emit("closecurrentonly");
        this.$emit("clearparentvalue");
      }
    },

    // function delete pack
    // ยังไม่มีลบ แบบ TB
    async multideletetotrash() {
      // this.$store.dispatch("set_loading", true).then((res) => {});
      console.log("mul del");
      console.log("filemultipledownload", this.filemultipledownload);
      let datalist = [];
      let status_data = "T";
      if (this.dataAccountActive.type == "Business") {
        this.$emit("multideleteforbusinsess");
      } else {
        status_data = "T";
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          console.log(this.filemultipledownload[i]);
          if (this.filemultipledownload[i].file_type === "folder") {
            console.log("folder");
            datalist.push({
              data_id: this.filemultipledownload[i]["file_id"],
              data_type: this.filemultipledownload[i]["file_type"],
              data_status: status_data,
            });
          } else {
            console.log("file");
            datalist.push({
              data_id: this.filemultipledownload[i]["file_id"],
              data_type: "file",
              data_status: status_data,
            });
          }
          // เช็คถ้าไม่ใช่ไฟล์ของฉันหรือเป็นแอดมินไม่ให้ลบ
          // if(this.filemultipledownload[i].file_owner_eng !== "Me" || this.role_level !== "True"){
          //   datalist.pop()
          // }
        }
        // console.log("Test check file and role", datalist);
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          data_list: datalist,
          // status_data:"T"
        };
        // console.log("payload multi delete right click", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.$store.dispatch("set_loading", false).then((res) => {});
              Toast.fire({
                icon: "success",
                title: this.$t("toast.multiset.remove"),
              });
              // this.statusmutipledownload = false;
              // this.clearmultipledownload();
              // this.loadfolder();
              this.$emit("reload");
              this.$emit("clearparentvalue");
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },

    // วนลูปเอาไฟล์เข้าถังขยะ
    async fn_multidelete() {
      // for (let i = 0; i < this.filemultipledownload.length; i++) {
      //   let payload = {
      //     account_id: this.dataAccountId,
      //     user_id: this.dataUsername,
      //     data_id: this.filemultipledownload[i]["file_id"],
      //     data_type: this.filemultipledownload[i].file_type === "folder" ? "folder" : "file",
      //     status_data: this.status_data,
      //   };
      //   await this.fn_delete(payload)
      // }
      console.log("this.listdragfile", this.listdragfile);
      for (let i = 0; i < this.listdragfile.length; i++) {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          data_id: this.listdragfile[i]["file_id"],
          data_type: this.listdragfile[i].file_type === "folder" ? "folder" : "file",
          status_data: this.status_data,
        };
        this.fn_delete(payload)
      }
      Toast.fire({
        icon: "success",
        title: this.$t("toast.text.multiremovetotrash")
      });
      this.$emit("reload");
      this.$emit("clearparentvalue");
      this.$emit('createevent');
      console.log("enddelete");
    },
    // เอาไฟล์ลงถังขยะทีละไฟล์
    async fn_delete(payload) {
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_data_to_trash", // then /api/remove_file_folder_to_trash
        data: payload,
        headers: { Authorization: auth.code }
      });
      try {
        if (result.data.status === "OK") {
          console.log("delete success");
        } else {
          console.log(result.data.errorMessage);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },


    // function star file
    async fn_setmultiupdate() {
      // console.log('aaaa',this.filemultipledownload,status);
      // listdragfile
      // if (this.filemultipledownload.length < 1) {
      if (this.listdragfile.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // let apifile;
        // let apifolder;
        // let payloadfile;
        // let payloadfolder;
        let msgres;
        let fileid = [];
        let starredid = [];
        let count_item_nostar = 0;
        let count_item_star = 0;
        // let status_star = "S";
        let status_star;
        let status = "S";

        for (let i = 0; i < this.listdragfile.length; i++) {
          if (this.listdragfile[i].file_status !== status) {
            count_item_nostar++;
            fileid.push(this.listdragfile[i]["file_id"]);
            starredid.push(this.listdragfile[i]["starred_id"]);
          } else {
            count_item_star++;
            fileid.push(this.listdragfile[i]["file_id"]);
            starredid.push(this.listdragfile[i]["starred_id"]);
          }
        }
        if (count_item_star == this.listdragfile.length) {
          status_star = "delete";
          status = "N";
        } else if (count_item_nostar <= this.listdragfile.length) {
          status_star = "insert";
          status = "S";
        }
        let payload = {
          starred_id: starredid,
          data_id: fileid,
          account_id: this.dataAccountId,
          process: status_star
        };
        let auth = await gbfGenerate.generateToken();
          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/multiple_star", payload, { headers: { Authorization: auth.code } })
            .then((response) => {
              // ติดดาวแบบไม่รีหน้า
              if (response.data.status === "OK") {
                for (let i = 0; i < response.data.data.length; i++) {
                  let objIndex = this.rootfile.findIndex((obj) => obj.file_id === response.data.data[i]["data_id"]);
                  if (objIndex !== -1) {
                    if (status === "S") {
                      this.rootfile[objIndex]["file_status"] = 'S';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      msgres = this.$t("toast.multiset.star");
                    } else if (status === "T") {
                      this.rootfile[objIndex]["file_status"] = 'T';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      msgres = this.$t("toast.multiset.remove");
                    } else {
                      this.rootfile[objIndex]["file_status"] = 'N';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      msgres = this.$t("toast.multiset.cancle");
                    }
                  }
                }
                Toast.fire({
                  icon: "success",
                  title: msgres,
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            })
            .catch((error) => {
              console.log("result ERROR !!!", error);
              Toast.fire({
                icon: "error",
                title: error,
              });
            });

        // for (i = 0; i < this.filemultipledownload.length; i++) {
        //   if (this.filemultipledownload[i].file_type === "folder") {
        //     folderdata.push(this.filemultipledownload[i]["file_id"]);
        //     payloadfolder = {
        //       list_folder: folderdata,
        //       account_id: this.dataAccountId,
        //       status_folder: status,
        //     };
        //     apifolder = "/api/multiple_update_status_folders";
        //   } else {
        //     filedata.push(this.filemultipledownload[i]["file_id"]);
        //     payloadfile = {
        //       list_file: filedata,
        //       account_id: this.dataAccountId,
        //       status_file: status,
        //     };
        //     apifile = "/api/multiple_update_status_files";
        //   }
        // }

        // อันเดิม
        // for (i = 0; i < this.listdragfile.length; i++) {
        //   if (this.listdragfile[i].file_type === "folder") {
        //     folderdata.push(this.listdragfile[i]["file_id"]);
        //     payloadfolder = {
        //       list_folder: folderdata,
        //       account_id: this.dataAccountId,
        //       status_folder: status,
        //     };
        //     apifolder = "/api/multiple_update_status_folders";
        //   } else {
        //     filedata.push(this.listdragfile[i]["file_id"]);
        //     payloadfile = {
        //       list_file: filedata,
        //       account_id: this.dataAccountId,
        //       status_file: status,
        //     };
        //     apifile = "/api/multiple_update_status_files";
        //   }
        // }
        // let auth = await gbfGenerate.generateToken();
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile, { headers: { Authorization: auth.code } })
        //   .then((response) => {
        //     if (response.data.status === "OK") {
        //       if (status === "S") {
        //         msgres = "ติดดาวสำเร็จ";
        //       } else if (status === "T") {
        //         msgres = "นำออกสำเร็จ";
        //       } else {
        //         msgres = "ยกเลิกติดดาวสำเร็จ";
        //       }
        //       Toast.fire({
        //         icon: "success",
        //         title: msgres,
        //       });
        //       // this.clearmultipledownload();
        //       this.$emit("reload");
        //       this.$emit("clearparentvalue");
        //     } else {
        //       Toast.fire({
        //         icon: "error",
        //         title: response.data.errorMessage,
        //       });
        //     }
        //   });
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder, {
        //     headers: { Authorization: auth.code },
        //   })
        //   .then((response) => {
        //     if (response.data.status === "OK") {
        //       if (status === "S") {
        //         msgres = "ติดดาวสำเร็จ";
        //       } else {
        //         msgres = "ยกเลิกติดดาวสำเร็จ";
        //       }
        //       Toast.fire({
        //         icon: "success",
        //         title: msgres,
        //       });
        //       // this.clearmultipledownload();
        //       // this.statusmutipledownload = false;
        //     } else {
        //       Toast.fire({
        //         icon: "error",
        //         title: response.data.errorMessage,
        //       });
        //     }
        //   });
      }
    },

    // emit
    fn_parentreload() {
      this.$emit("reload");
    },
  },
};
</script>

<style></style>
